@tailwind base;
@tailwind components;
@tailwind utilities;

/* Date custom style */
input[type="date"] {
  display: block;
  height: 42px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

/* All input boxes should be same height */
input {
  height: 42px;
}

/* Remove default arrow buttons from number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox number buttons from input */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}

/* Fix for dropdown, consistent on all browsers */
select {
  background: url("../public/select-arrows.svg") no-repeat 98% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Disable IE dropdown style */
select::-ms-expand {
  display: none;
}
